export const appConst = {
    formEditorType: {
        text_box: 1,
        combobox: 2,
        date_box: 3
    },
    formEditorFixed: {
        dien_thoai: 2
    }
}

