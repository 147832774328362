import appInfo from "../app-info";
import { apiHelper, call_post_api } from "./api_helper";

export const FORMITEM_API_ENDPOINT = "form-item";


export const formItemApi = {
    Select: (ts_form_id) => apiHelper.getWithoutCheckToken(`${FORMITEM_API_ENDPOINT}/${ts_form_id}`),
    Insert: (data) => apiHelper.post(`${FORMITEM_API_ENDPOINT}`, data),
    Update: (data) => apiHelper.put(`${FORMITEM_API_ENDPOINT}`, data),
    Delete: (id) => apiHelper.delete(`${FORMITEM_API_ENDPOINT}/${id}`),
}