import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { formEditorApi } from '../../../api/formEditorApi';
const FormCobobox = ({ value, ts_form_editor_id, onValueChanged }) => {
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    handleReload();
  }, []);
  const handleReload = async () => {
    const res = await formEditorApi.SelectDataSource(ts_form_editor_id);
    if (res.is_success) {
      setDataSource(res.data);
    }
  };
  return (
    <SelectBox
      dataSource={dataSource}
      displayExpr={'text'}
      valueExpr={'id'}
      //   placeholder={translate("Chọn năm học")}
      value={value}
      stylingMode="underlined"
      width={'100%'}
      onValueChanged={(e) => {
        if (e.event && onValueChanged) {
          onValueChanged(e.value);
        }
      }}
    //   ref={cmb}
    //   onValueChanged={(e) => {
    //     onValueChanged(e.value);
    //   }}
    />
  );
};

export default FormCobobox;
