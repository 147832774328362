import { Button, DateBox, TextBox } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formApi } from '../../api/formApi';
import { formItemApi } from '../../api/formItemApi';
import { formRegisterApi } from '../../api/formRegisterApi';
import { useScreenSizeClass } from '../../utils/media-query';
import { FormCobobox } from '../form/Editor';
import styles from './FormRegisterPage.module.css';
import { appConst } from '../../app-const';
const FormRegisterPage = ({ isAttendance, dmChienDichId, tsFormId }) => {
  const screenSizeClass = useScreenSizeClass();
  const location = useLocation();

  const params = location.pathname.replace('/form-register/', '').split('/');
  const dm_chiendich_id = dmChienDichId || (params.length > 0 ? parseInt(params[0]) : 0);
  const ts_form_id = tsFormId || (params.length > 1 ? parseInt(params[1]) : 0);

  const [ts_form, set_ts_form] = useState({});
  const [ts_form_items, set_ts_form_items] = useState([]);
  const [ts_form_register_result_id, set_ts_form_register_result_id] =
    useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    dm_chiendich_id: dm_chiendich_id,
  });

  useEffect(() => {
    if (dm_chiendich_id > 0 && ts_form_id > 0 && ts_form_register_result_id > 0) {
      //lưu cache để điểm danh
      localStorage.setItem(`${dm_chiendich_id}-${ts_form_id}`, ts_form_register_result_id);
    }
  }, [ts_form_register_result_id, ts_form_id, dm_chiendich_id])

  useEffect(() => {
    handleReloadForm();
  }, [ts_form_id]);
  useEffect(() => {
    if (ts_form && ts_form.id > 0) {
      setFormData({
        ...formData,
        ts_form_id: ts_form.id,
      });
      handleReloadFormItems();
    }
  }, [ts_form]);
  useEffect(() => {
    setFormData({
      ...formData,
      result: ts_form_items.map((x) => {
        return {
          ts_form_item_id: x.id,
          ts_form_editor_id: x.ts_form_editor_id,
          value: '',
        };
      }),
    });
  }, [ts_form_items]);
  const phone_number = useMemo(() => {
    if (formData.result) {
      const phone_number_result = formData.result.find(
        (x) => x.ts_form_editor_id == appConst.formEditorFixed.dien_thoai
      );
      if (phone_number_result) return phone_number_result.value;
    }

    return '';
  }, [formData]);
  useEffect(() => {
    if (isAttendance) {
      hanleAutoFill();
    }

  }, [phone_number, isAttendance])
  const handleReloadForm = async () => {
    const res = await formApi.Detail(ts_form_id);
    if (res.is_success) {
      set_ts_form(res.data);

    }
  };
  const handleReloadFormItems = async () => {
    const res = await formItemApi.Select(ts_form.id);
    if (res.is_success) {
      set_ts_form_items(res.data);

    }
  };
  const handleValueChanged = (ts_form_item_id, value) => {
    setFormData({
      ...formData,
      result: formData.result.map((x) => {
        if (x.ts_form_item_id == ts_form_item_id) {
          return {
            ...x,
            ts_form_item_id: x.ts_form_item_id,
            value: value,
          };
        } else {
          return { ...x };
        }
      }),
    });
  };
  const handleSubmit = async () => {
    setIsSaving(true);
    const res = await formRegisterApi.Insert({
      dm_chiendich_id: dm_chiendich_id,
      ts_form_id: formData.ts_form_id,
      phone_number: phone_number,
      is_attendanced: isAttendance || false,
      result: formData.result.map((x) => {
        return {
          ts_form_item_id: x.ts_form_item_id,
          value: x.value,
          ts_form_editor_id: x.ts_form_editor_id,
        };
      }),
    });
    setIsSaving(false);
    if (res.is_success) {
      set_ts_form_register_result_id(res.data);
    }
  };
  const hanleAutoFill = async () => {
    const res = await formRegisterApi.SelecDetailListByPhone(dm_chiendich_id, phone_number);
    if (res.is_success) {
      setFormData({
        ...formData,
        result: formData.result.map((x) => {
          const existedData = res.data.find(y => y.ts_form_item_id == x.ts_form_item_id)
          console.log({
            existedData
          });
          return {
            ...x,
            value: existedData ? existedData.value : "",
          };

        }),
      });
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.form + ' ' + screenSizeClass}>
          <div className={styles.cover_img_container}>
            <img src={ts_form.cover_img} />
          </div>
          <div className={styles.form_area}>
            <div className={styles.caption}>
              <p>
                <i className="fas fa-poll" style={{ marginRight: 10 }}></i>
                {ts_form.name}
              </p>
            </div>
            {ts_form_register_result_id > 0 && (
              <div className={styles.result}>
                <img src="../../../images/thank-you.png" />
                <p>
                  Quý Phụ huynh đã đăng ký thành công, mã đăng ký của Quý Phụ huynh là:<b> #{ts_form_register_result_id}</b>
                </p>
              </div>
            )}
            {ts_form_register_result_id == 0 && (
              <div>
                {ts_form_items.map((x) => {
                  const existedData = formData.result.find(y => y.ts_form_item_id == x.id)

                  return (
                    <div key={x.id} className={styles.form_group}>
                      <div className={styles.form_label}>{x.label}</div>
                      {x.ts_form_editor_type_id == appConst.formEditorType.text_box && (
                        <TextBox
                          stylingMode="underlined"
                          value={existedData?.value}
                          onValueChanged={(e) => {
                            if (e.event) {
                              handleValueChanged(x.id, e.value);
                            }
                          }}
                        />
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.combobox && (
                        <FormCobobox
                          ts_form_editor_id={x.ts_form_editor_id}
                          value={existedData?.value}
                          onValueChanged={(value) => {
                            handleValueChanged(x.id, value);
                          }}
                        />
                      )}

                      {existedData?.value && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          displayFormat={'dd/MM/yyyy'}
                          value={moment(existedData?.value, 'DD/MM/YYYY')}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              handleValueChanged(x.id, date);
                            }
                          }}
                        />
                      )}
                      {!existedData?.value && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          displayFormat={'dd/MM/yyyy'}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              handleValueChanged(x.id, date);
                            }
                          }}
                        />
                      )}
                      <div className={styles.form_help_text}>{x.help_text}</div>
                    </div>
                  );
                })}
                <div className={styles.form_actions}>
                  <Button
                    text={'Xác nhận'}
                    width={'100%'}
                    type="default"
                    onClick={handleSubmit}
                    disabled={isSaving}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormRegisterPage;
