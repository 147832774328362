import React from 'react';
import UploadImgButton from '../../../components/file-upload/UploadImg';
import { useCommonContext } from '../../../contexts/common';
import styles from './FormCoverImgEditor.module.css';
const FormCoverImgEditor = ({ src, onUploaded }) => {
  
  const { translate } = useCommonContext();

  return (
    <React.Fragment>
      <div className={styles.container}>
        {src && src != '' && (
          <>
            <img className={styles.cover_img} src={src} />
            <p className={styles.remove_img_container}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onUploaded('');
                }}
              >
                {translate('Xóa ảnh cover này')}
              </a>
            </p>
          </>
        )}
        {(!src || src == '') && (
          <div className={styles.add_img_container}>
            {/* <DefaultButton text={"Upload ảnh cover"} /> */}
            <UploadImgButton
              onSuccess={(value) => {
                // console.log(value)
                onUploaded(value);
              }}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default FormCoverImgEditor;
