import { LoadPanel } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formRegisterApi } from '../../api/formRegisterApi';
import { useScreenSizeClass } from '../../utils/media-query';
import FormRegisterPage from '../form-register/FormRegisterPage';
import styles from "./FormAttendancePage.module.css"
const FormAttendancePage = () => {
    const screenSizeClass = useScreenSizeClass();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    const params = location.pathname.replace('/form-register/', '').split('/');
    const dm_chiendich_id = params.length > 0 ? parseInt(params[0]) : 0;
    const ts_form_id = params.length > 1 ? parseInt(params[1]) : 0;

    useEffect(() => {
        const cachedData = localStorage.getItem(`${dm_chiendich_id}-${ts_form_id}`);
        if (cachedData) {
            const ts_form_register_result_id = parseInt(cachedData);
            if (ts_form_register_result_id > 0) {
                handeleAttendance({ ts_form_register_result_id })
            }
        } else {
            setIsLoading(false);
        }


    }, [dm_chiendich_id, ts_form_id]);

    const handeleAttendance = async ({ ts_form_register_result_id }) => {
        const res = await formRegisterApi.Attendance({
            id: ts_form_register_result_id,
            is_attendanced: true
        });
        if (res.is_success) {
            setIsSuccess(true);
            setIsLoading(false);
        } else {
            setIsSuccess(false);
            setIsLoading(false);
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.form_container}>
                <div className={styles.form + ' ' + screenSizeClass}>

                    {isLoading && <LoadPanel visible />}
                    {!isLoading && <>
                        {isSuccess && <AttendanceSuccess />}
                        {!isSuccess && <FormRegisterPage dmChienDichId={dm_chiendich_id} tsFormId={ts_form_id} isAttendance={true} />}
                    </>}

                </div>
            </div>
        </div>

    );
};
const AttendanceSuccess = () => {
    return (
        <>
            <div className={styles.result}>
                <img src="../../../images/thank-you.png" />
                <p>
                    Quý Phụ huynh đã điểm danh thành công !
                </p>
            </div>
        </>
    );
}
export default FormAttendancePage;