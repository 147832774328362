import { apiHelper } from "./api_helper";

export const FORM_REGISTER_API_END_POINT = "form-result";
export const FORM_REGISTER_DETAIL_END_POINT = "form-result-detail";


export const formRegisterApi = {
    Insert: (data) => apiHelper.postWithoutCheckToken(`${FORM_REGISTER_API_END_POINT}`, data),
    Select: (dm_chiendich_id) => apiHelper.get(`${FORM_REGISTER_API_END_POINT}/${dm_chiendich_id}`),
    UpdateDetail: (data) => apiHelper.put(`${FORM_REGISTER_DETAIL_END_POINT}`, data),
    SelecDetailList: (dm_chiendich_id) => apiHelper.get(`${FORM_REGISTER_DETAIL_END_POINT}/${dm_chiendich_id}`),
    SelecDetailListByPhone: (dm_chiendich_id, phone_number) => apiHelper.getWithoutCheckToken(`${FORM_REGISTER_DETAIL_END_POINT}/${dm_chiendich_id}/${phone_number}`),
    Attendance: (data) => apiHelper.postWithoutCheckToken(`${FORM_REGISTER_API_END_POINT}/attendance`, data)
}