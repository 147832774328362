import appInfo from "../app-info";
import { apiHelper, call_post_api } from "./api_helper";

export const FORMEDITORAPI_ENDPOINT = "form-editor";


export const formEditorApi = {
    SelectAll: () => apiHelper.get(`${FORMEDITORAPI_ENDPOINT}`),
    SelectDataSource: (id) => apiHelper.getWithoutCheckToken(`${FORMEDITORAPI_ENDPOINT}/datasource/${id}`),
    Insert: (data) => apiHelper.post(`${FORMEDITORAPI_ENDPOINT}`, data),
    Update: (data) => apiHelper.put(`${FORMEDITORAPI_ENDPOINT}`, data),
    Delete: (id) => apiHelper.delete(`${FORMEDITORAPI_ENDPOINT}/${id}`),
}