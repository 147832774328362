import { apiHelper } from "./api_helper";

export const FORM_API_ENDPOINT = "form";


export const formApi = {
    SelectAll: () => apiHelper.get(`${FORM_API_ENDPOINT}`),
    Detail: (id) => apiHelper.getWithoutCheckToken(`${FORM_API_ENDPOINT}/${id}`),
    Insert: (data) => apiHelper.post(`${FORM_API_ENDPOINT}`, data),
    Update: (data) => apiHelper.put(`${FORM_API_ENDPOINT}`, data),
    Delete: (id) => apiHelper.delete(`${FORM_API_ENDPOINT}/${id}`),
}