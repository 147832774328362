import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react'
import { useCommonContext } from '../../contexts/common';
import './button.css'
import styles from "./button.module.css";
import clsx from "clsx"
import { Tooltip } from 'devextreme-react/tooltip';
import { v4 as uuidv4 } from 'uuid';
const PrimaryButton = ({ disabled, onClick, text, isLoading, icon, tooltipText, id }) => {
    const { translate } = useCommonContext();
    const [isShowTooltip, setisShowTooltip] = useState(false);
    const btnId = id ? id : uuidv4();

    return (
        <>
            <button
                id={`btn${btnId}`}
                type="button"
                // disabled={disabled || isLoading}
                onClick={() => {
                    if (!disabled) onClick();
                }}
                className={clsx(styles.btn, styles.btn_primary, disabled ? styles.btn_disabled : "")}
                onMouseOver={() => { if (tooltipText) setisShowTooltip(true) }}
                onMouseOut={() => { if (tooltipText) setisShowTooltip(false) }}

            >
                {icon && <i className={clsx(icon, styles.icon)}></i>}
                {translate(text)}
                {isLoading && <Loader active inline inverted size="mini" />}
            </button>

            <Tooltip
                target={`#btn${btnId}`}
                visible={isShowTooltip && tooltipText}
                hideOnOutsideClick={false}
            >
                <div>{tooltipText}</div>
            </Tooltip>

        </>

    );
}
const DefaultButton = ({ disabled, onClick, text, isLoading, icon, id, tooltipText }) => {
    const { translate } = useCommonContext();
    const [isShowTooltip, setisShowTooltip] = useState(false);
    const btnId = id ? id : uuidv4();
    return (
        <>
            <button
                id={`btn${btnId}`}
                type="button"
                // disabled={disabled || isLoading}
                onClick={() => {
                    if (!disabled) onClick();
                }}
                className={clsx(styles.btn, disabled ? styles.btn_disabled : "")}
                onMouseOver={(e) => {
                    if (tooltipText) setisShowTooltip(true)
                }}
                onMouseOut={() => { if (tooltipText) setisShowTooltip(false) }}

            >
                {icon && <i className={clsx(icon, styles.icon)}></i>}
                {translate(text)}
                {isLoading && <Loader active inline inverted size="mini" />}
            </button>
            <Tooltip
                target={`#btn${btnId}`}
                visible={isShowTooltip && tooltipText}
                hideOnOutsideClick={false}
            >
                <div>{tooltipText}</div>
            </Tooltip>
        </>
    );
}
const DangerButton = ({ disabled, onClick, text, isLoading, icon, id, tooltipText }) => {
    const { translate } = useCommonContext();
    const [isShowTooltip, setisShowTooltip] = useState(false);
    const btnId = id ? id : uuidv4();

    return (
        <>
            <button
                id={`btn${btnId}`}
                type="button"
                // disabled={disabled || isLoading}
                onClick={() => {
                    if (!disabled) onClick();
                }}
                className={clsx(styles.btn, styles.btn_danger, disabled ? styles.btn_disabled : "")}
                onMouseOver={(e) => {
                    if (tooltipText) setisShowTooltip(true)
                }}
                onMouseOut={() => { if (tooltipText) setisShowTooltip(false) }}

            >
                {icon && <i className={clsx(icon, styles.icon)}></i>}
                {translate(text)}
                {isLoading && <Loader active inline inverted size="mini" />}
            </button>
            <Tooltip
                target={`#btn${btnId}`}
                visible={isShowTooltip && tooltipText}
                hideOnOutsideClick={false}
            >
                <div>{tooltipText}</div>
            </Tooltip>
        </>

    );
}
export { PrimaryButton, DefaultButton, DangerButton };