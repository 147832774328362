import { apiHelper } from './api_helper';

export const TRUONG_API_ENDPOIT = 'truong';
export const truongApi = {
    SelectAll: () => apiHelper.get('truong'),
    SelectTruongKhoiHe: () => apiHelper.get('truong/khoi/he'),
    select_by_id: (id) => apiHelper.get(`${TRUONG_API_ENDPOIT}/${id}`),
    insert: (data) => apiHelper.post(TRUONG_API_ENDPOIT, data),
    update:  (data) => apiHelper.put(TRUONG_API_ENDPOIT, data),
    remove: (id) => apiHelper.delete(`${TRUONG_API_ENDPOIT}/${id}`),
    selectKhoiByTruong: (dm_truong_id) => apiHelper.get(`truong/${dm_truong_id}/khoi`),
    update_khoi_by_truong_id: (data) => apiHelper.post(`truong/update/khoi`,data),
    delete_khoi_by_truong_id: (data) => apiHelper.post(`truong/delete/khoi`,data),
}
